import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'

const usePermissions = () => {
  const { permissionList } = useSelector((state: RootState) => state.permission)

  const canAccessStock = permissionList.stock.read
  const canAccessAttendu = permissionList.attendu.read
  const canAccessCommande = permissionList.commande.read
  const canAccessReference = permissionList.reference.read
  const canAccessMission = permissionList.mission.read
  const canAccessHistory = permissionList.history.read

  const canCreateAttendu = permissionList.attendu.create
  const canCreateCommande = permissionList.commande.create
  const canCreateReference = permissionList.reference.create

  const canUpdateStock = permissionList.stock.update
  const canUpdateAttendu = permissionList.attendu.update
  const canUpdateCommande = permissionList.commande.update
  const canUpsertMission = permissionList.mission.upsert

  const canDeleteReference = permissionList.reference.delete
  const canDeleteAttendu = permissionList.attendu.delete
  const canDeleteCommande = permissionList.commande.delete
  const canDeleteMission = permissionList.mission.delete

  const canImportReference = permissionList.reference.import
  const canImportAttendu = permissionList.attendu.import
  const canImportCommande = permissionList.commande.import

  const canExportStock = permissionList.stock.export
  const canExportAttendu = permissionList.attendu.export
  const canExportCommande = permissionList.commande.export
  const canExportReference = permissionList.reference.export

  return {
    canAccessStock,
    canAccessAttendu,
    canAccessCommande,
    canAccessReference,
    canAccessMission,
    canAccessHistory,
    canCreateReference,
    canCreateAttendu,
    canCreateCommande,
    canUpdateStock,
    canUpdateAttendu,
    canUpdateCommande,
    canUpsertMission,
    canDeleteReference,
    canDeleteAttendu,
    canDeleteCommande,
    canDeleteMission,
    canImportReference,
    canImportAttendu,
    canImportCommande,
    canExportStock,
    canExportAttendu,
    canExportCommande,
    canExportReference,
  }
}

export default usePermissions
