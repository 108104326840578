import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PermissionList } from '../../models/Permission'
import { UserRole } from '../../enum/UserRole'

interface PermissionState {
  role: UserRole
  permissionList: PermissionList
}

export const initPermissionList: PermissionList = {
  stock: {
    read: true,
    update: true,
    export: true,
  },
  attendu: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  commande: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  reference: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  mission: {
    read: true,
    upsert: true,
    delete: true,
  },
  history: {
    read: true,
  },
}

const initialState: PermissionState = {
  role: UserRole.SUPERADMIN,
  permissionList: initPermissionList,
}

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermissions(state, action: PayloadAction<any>) {
      state.permissionList = action.payload.permissionList
    },
    setRole(state, action: PayloadAction<any>) {
      state.role = action.payload.role
    },
  },
})

const permissionReducer = permissionSlice.reducer

export default permissionReducer

export const { setPermissions, setRole } = permissionSlice.actions
