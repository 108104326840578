function getCurrentOffset() {
  const now = new Date()
  return -now.getTimezoneOffset() / 60 // Convert from minutes to hours
}

export function formatTimeLocal(time: string) {
  if (!time) return ''
  const offset = getCurrentOffset()
  const date = new Date(time)
  if (isNaN(date.getTime())) return ''

  // Convert the UTC time to the specified timezone
  const localTime = new Date(date.getTime() + offset * 60 * 60 * 1000)

  const day = String(localTime.getUTCDate()).padStart(2, '0')
  const month = String(localTime.getUTCMonth() + 1).padStart(2, '0')
  const year = String(localTime.getUTCFullYear()).slice(-2) // Get last two digits of the year
  const hours = String(localTime.getUTCHours()).padStart(2, '0')
  const minutes = String(localTime.getUTCMinutes()).padStart(2, '0')
  // Format the local time as dd/mm/yy - hh:mm
  return `${day}/${month}/${year} - ${hours}:${minutes}`
}
