import baseAxios from './clientAPI'

interface GetPermissionsParams {
  clientId?: string
  companyId?: string
  warehouseId?: string
}

export const CLIENT_HEADER = 'Client-Id'
export const COMPANY_HEADER = 'Company-Id'
export const WAREHOUSE_HEADER = 'Warehouse-Id'
export const permissionsUrlPath = '/warehouse/profile/permissions'

const profileApi = {
  getPermissions(params: GetPermissionsParams) {
    return baseAxios.get(permissionsUrlPath, {
      headers: {
        [CLIENT_HEADER]: params.clientId,
        [COMPANY_HEADER]: params.companyId,
        [WAREHOUSE_HEADER]: params.warehouseId,
      },
    })
  },
}

export default profileApi
