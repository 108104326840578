import { ModalName } from '../../features/stock/modal/customModalSlice'
import { useChangeMenuColor } from '../../hook/useChangeMenuColor'

export default function MissionsNavIcon() {
  const isChangeColor = useChangeMenuColor(ModalName.MISSION)
  return (
      <svg width="14" height="14" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.9948 12.6C25.491 12.6 25.0707 12.2239 24.9685 11.7305C24.5263 9.59642 23.4696 7.63062 21.9195 6.08053C20.3694 4.53044 18.4036 3.47371 16.2695 3.03155C15.7761 2.92933 15.4 2.50899 15.4 2.00515V1C15.4 0.447714 14.9523 0 14.4 0H13.6C13.0477 0 12.6 0.447716 12.6 1V2.00516C12.6 2.50899 12.2239 2.92933 11.7305 3.03155C9.59642 3.47371 7.63062 4.53044 6.08053 6.08053C4.53044 7.63062 3.47371 9.59642 3.03155 11.7305C2.92933 12.2239 2.50899 12.6 2.00515 12.6H0.999999C0.447714 12.6 0 13.0477 0 13.6V14.4C0 14.9523 0.447715 15.4 1 15.4H2.00515C2.50899 15.4 2.92933 15.7761 3.03155 16.2695C3.47371 18.4036 4.53044 20.3694 6.08053 21.9195C7.63062 23.4696 9.59642 24.5263 11.7305 24.9685C12.2239 25.0707 12.6 25.491 12.6 25.9948V27C12.6 27.5523 13.0477 28 13.6 28H14.4C14.9523 28 15.4 27.5523 15.4 27V25.9948C15.4 25.491 15.7761 25.0707 16.2695 24.9685C18.4036 24.5263 20.3694 23.4696 21.9195 21.9195C23.4696 20.3694 24.5263 18.4036 24.9685 16.2695C25.0707 15.7761 25.491 15.4 25.9948 15.4H27C27.5523 15.4 28 14.9523 28 14.4V13.6C28 13.0477 27.5523 12.6 27 12.6H25.9948ZM14 5.6C16.2278 5.6 18.3644 6.485 19.9397 8.0603C21.515 9.63561 22.4 11.7722 22.4 14C22.4 16.2278 21.515 18.3644 19.9397 19.9397C18.3644 21.515 16.2278 22.4 14 22.4C11.7722 22.4 9.63561 21.515 8.0603 19.9397C6.485 18.3644 5.6 16.2278 5.6 14C5.6 11.7722 6.485 9.63561 8.0603 8.0603C9.63561 6.485 11.7722 5.6 14 5.6ZM14 9.8C15.1139 9.8 16.1822 10.2425 16.9698 11.0302C17.7575 11.8178 18.2 12.8861 18.2 14C18.2 15.1139 17.7575 16.1822 16.9698 16.9698C16.1822 17.7575 15.1139 18.2 14 18.2C12.8861 18.2 11.8178 17.7575 11.0302 16.9698C10.2425 16.1822 9.8 15.1139 9.8 14C9.8 12.8861 10.2425 11.8178 11.0302 11.0302C11.8178 10.2425 12.8861 9.8 14 9.8Z"
              fill={isChangeColor ? '#f09637' : 'white'}/>
      </svg>
  )
}

