import { ModalName } from '../../features/stock/modal/customModalSlice'
import { useChangeMenuColor } from '../../hook/useChangeMenuColor'

export default function AttenduNavIcon() {
  const isChangeColor = useChangeMenuColor(ModalName.ATTENDU)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="19"
      viewBox="0 0 25 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.666504 2C0.666504 0.895432 1.56193 0 2.6665 0H10.3134C11.418 0 12.3134 0.895431 12.3134 2V14.941C12.3134 16.0456 11.418 16.941 10.3134 16.941C10.2044 16.941 10.1273 16.834 10.1557 16.7287C10.2398 16.4168 10.2846 16.0888 10.2846 15.7503C10.2846 13.6793 8.6057 12.0004 6.53465 12.0004C4.4636 12.0004 2.78469 13.6793 2.78469 15.7503C2.78469 16.0576 2.82165 16.3563 2.89138 16.6421C2.92764 16.7908 2.8195 16.941 2.6665 16.941C1.56193 16.941 0.666504 16.0456 0.666504 14.941V2ZM8.78463 15.7503C8.78463 16.5309 7.96994 16.941 7.18942 16.941H5.87988C5.09936 16.941 4.28467 16.5309 4.28467 15.7503C4.28467 14.5077 5.29202 13.5004 6.53465 13.5004C7.77728 13.5004 8.78463 14.5077 8.78463 15.7503ZM3.00005 1.33346C2.44776 1.33346 2.00005 1.78118 2.00005 2.33346V3.00012C2.00005 3.55241 2.44776 4.00012 3.00005 4.00012H9.6667C10.219 4.00012 10.6667 3.55241 10.6667 3.00013V2.33346C10.6667 1.78118 10.219 1.33346 9.6667 1.33346H3.00005Z"
        fill={isChangeColor ? '#f09637' : 'white'}
      />
      <path
        d="M8.78364 15.75C8.78364 16.9926 7.7763 18 6.53367 18C5.29104 18 4.28369 16.9926 4.28369 15.75C4.28369 14.5073 5.29104 13.5 6.53367 13.5C7.7763 13.5 8.78364 14.5073 8.78364 15.75Z"
        fill={isChangeColor ? '#f09637' : 'white'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.4312 14.5003V6.88683C14.4312 5.26008 16.2699 4.31383 17.5936 5.25936L24.0935 9.90214C24.6191 10.2776 24.931 10.8837 24.931 11.5296V14.5003C24.931 15.2058 24.5658 15.8259 24.0141 16.182C23.7329 16.3634 23.4309 16.0848 23.4309 15.7502C23.4309 13.6791 21.752 12.0002 19.6809 12.0002C17.6099 12.0002 15.931 13.6791 15.931 15.7502C15.931 16.0848 15.6289 16.3633 15.3479 16.1818C14.7963 15.8257 14.4312 15.2057 14.4312 14.5003ZM21.9309 15.7502C21.9309 16.2085 21.4964 16.5003 21.0382 16.5003H18.3237C17.8654 16.5003 17.431 16.2085 17.431 15.7502C17.431 14.5076 18.4383 13.5002 19.6809 13.5002C20.9236 13.5002 21.9309 14.5076 21.9309 15.7502ZM15.3333 6.58737V8.33337C15.3333 8.88566 15.781 9.33337 16.3333 9.33337H18.8276C19.8053 9.33337 20.202 8.07482 19.4011 7.51414L16.9068 5.76814C16.244 5.3042 15.3333 5.77835 15.3333 6.58737Z"
        fill={isChangeColor ? '#f09637' : 'white'}
      />
      <path
        d="M21.9316 15.75C21.9316 16.9926 20.9242 18 19.6816 18C18.439 18 17.4316 16.9926 17.4316 15.75C17.4316 14.5073 18.439 13.5 19.6816 13.5C20.9242 13.5 21.9316 14.5073 21.9316 15.75Z"
        fill={isChangeColor ? '#f09637' : 'white'}
      />
    </svg>
  )
}
