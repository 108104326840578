import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import './assets/scss/bottom.scss'
import './assets/scss/color.scss'
import './assets/scss/common.scss'
import './assets/scss/customize-antd.scss'
import './assets/scss/heading.scss'
import './assets/scss/icon.scss'
import './assets/scss/noti.scss'
import './assets/scss/position.scss'
import './assets/scss/sizing.scss'
import './assets/scss/spacing.scss'
import './features/stock/modal/type/attendu/Attendu.scss'
import './features/stock/attendu/Attendu.scss'
import './features/stock/modal/CustomModal.scss'
import { NotFound } from './components/Common'
import { CommandeType } from './enum/CommandeType'
import { MODE } from './enum/mode'
import { ScreenType } from './enum/ScreenType'

import usePressTab from './hook/usePressTab'
import { lazy, ReactNode, Suspense } from 'react'
import { Spin } from 'antd'
import { NavBar } from './components/Common/Nav/Nav'
import usePermissions from './hook/usePermissions'
import { showPermissionError } from './utils/notification'
import ErrorBoundary from './components/Common/ErrorBoundary'
import AuthGuard from './guard/AuthGuard'
import HomeLayout from './components/Layout/HomeLayout'
import { t } from 'i18next'

const lazyRetry: any = function (componentImport: any) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    )
    // try to import the component
    componentImport()
      .then((component: ReactNode) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh
        resolve(component)
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true') // we are now going to refresh
          return window.location.reload() // refresh the page
        }
        reject(error) // Default error behaviour as already tried refresh
      })
  })
}

const CommandeHistory = lazy(() =>
  lazyRetry(() => import('./features/history/commande/CommandeHistory'))
)

const HistoryGeneral = lazy(() =>
  lazyRetry(() => import('./features/history/general/HistoryGeneral'))
)
const AttenduHistory = lazy(() =>
  lazyRetry(() => import('./features/history/attendu/AttenduHistory'))
)
const SsccHistory = lazy(() =>
  lazyRetry(() => import('./features/history/sscc/SsccHistory'))
)
const StockCreate = lazy(() =>
  lazyRetry(
    () => import('./features/stock/stockscreen/component/modal/StockCreate')
  )
)
const TransporteurView = lazy(() =>
  lazyRetry(
    () => import('./features/stock/modal/type/commonModal/TransporteurViewOnly')
  )
)
const FournisseurView = lazy(() =>
  lazyRetry(
    () => import('./features/stock/modal/type/commonModal/FournisseurViewOnly')
  )
)
const DestinataireView = lazy(() =>
  lazyRetry(
    () => import('./features/stock/modal/type/commonModal/DestinataireViewOnly')
  )
)
const TestPdf = lazy(() =>
  lazyRetry(() => import('./features/stock/modal/type/commande/TestPdf'))
)
const CommandeMisEnPreparation = lazy(() =>
  lazyRetry(
    () =>
      import('./features/stock/modal/type/commande/CommandeMisEnPreparation')
  )
)
const CommandeEdit = lazy(() =>
  lazyRetry(() => import('./features/stock/modal/type/commande/CommandeEdit'))
)
const AttenduEdit = lazy(() =>
  lazyRetry(() => import('./features/stock/modal/type/attendu/AttenduEdit'))
)
const AttenduCreate = lazy(() =>
  lazyRetry(() => import('./features/stock/modal/type/attendu/AttenduCreate'))
)

const AttenduOnMission = lazy(() =>
  lazyRetry(() => import('./features/stock/attendu/AttenduOnMission'))
)

const ReferenceHistory = lazy(() =>
  lazyRetry(() => import('./features/history/reference/ReferenceHistory'))
)
const UtilisateurCreate = lazy(() =>
  lazyRetry(
    () => import('./features/configurations/utilisateur/UtilisateurCreate')
  )
)
const UtilisateurScreen = lazy(() =>
  lazyRetry(() => import('./features/configurations/utilisateur/Utilisateur'))
)
const Societe = lazy(() =>
  lazyRetry(
    () => import('./features/configurations/societe/societeDetail/Societe')
  )
)
const RefEdit = lazy(() =>
  lazyRetry(
    () => import('./features/configurations/reference/catalogue/RefEdit')
  )
)
const ClientScreen = lazy(() =>
  lazyRetry(() => import('./features/configurations/client/ClientScreen'))
)

const ClientCreate = lazy(() =>
  lazyRetry(
    () => import('./features/configurations/client/clientDetail/ClientCreate')
  )
)

const CommandeCreate = lazy(() =>
  lazyRetry(() => import('./features/stock/modal/type/commande/CommandeCreate'))
)

const DashboardComponent = lazy(() =>
  lazyRetry(() => import('./features/dashboard/Dashboard'))
)

const StockComponent = lazy(() =>
  lazyRetry(() => import('./features/stock/stockscreen/NewStock'))
)
const AttenduComponent = lazy(() =>
  lazyRetry(() => import('./features/stock/attendu/Attendu'))
)
const CommandeComponent = lazy(() =>
  lazyRetry(() => import('./features/stock/commande/Commande'))
)
const ReferenceComponent = lazy(() =>
  lazyRetry(() => import('./features/configurations/reference/ReferenceScreen'))
)

const SocieteComponent = lazy(() =>
  lazyRetry(() => import('./features/configurations/societe/SocieteScreen'))
)

const ConditionnementPopup = lazy(() =>
  lazyRetry(
    () =>
      import(
        './features/stock/stockscreen/component/modal/ConditionnementPopup'
      )
  )
)

const EmplacementPopup = lazy(() =>
  lazyRetry(
    () =>
      import('./features/stock/stockscreen/component/modal/EmplacementPopup')
  )
)

const ClientEdit = lazy(() =>
  lazyRetry(
    () => import('./features/configurations/client/clientDetail/ClientEdit')
  )
)

const MissionComponent = lazy(() =>
  lazyRetry(() => import('./features/stock/mission/Mission'))
)

const MissionDetailComponent = lazy(() =>
  lazyRetry(() => import('./features/stock/mission/MissionDetail'))
)

const ProtectedRoute = ({ children, ...rest }: any) => (
  <HomeLayout {...rest}>{children}</HomeLayout>
)

export const PermissionRoute = ({
  hasPermission,
}: {
  hasPermission: boolean
}) => {
  if (!hasPermission) {
    showPermissionError()
    return <Navigate to={'/tableau-de-bord'} />
  }
  return <Outlet />
}

function App() {
  usePressTab()
  const {
    canAccessStock,
    canAccessAttendu,
    canAccessCommande,
    canAccessMission,
    canAccessHistory,
  } = usePermissions()

  return (
    <div>
      <div className="fixed top-0 w-full z-50">
        <NavBar />
      </div>
      <AuthGuard>
        <ErrorBoundary>
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center">
                <Spin size="large" />
              </div>
            }
          >
            <Routes>
              <Route
                path="*"
                element={
                  <ProtectedRoute>
                    <NotFound />
                  </ProtectedRoute>
                }
              />

              {/* dashboard routes */}
              <Route
                path="/"
                element={<Navigate to={'/tableau-de-bord'} replace />}
              ></Route>
              <Route
                path="tableau-de-bord"
                element={
                  <ProtectedRoute>
                    <DashboardComponent />
                  </ProtectedRoute>
                }
              />

              {/* stock routes */}
              <Route
                path="/gestion-de-stock/stock"
                element={<PermissionRoute hasPermission={canAccessStock} />}
              >
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <StockComponent />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="chrono/reference/:id"
                  element={<ReferenceHistory />}
                />
                <Route path="chrono/sscc/:sscc" element={<SsccHistory />} />
                <Route path="stock-create" element={<StockCreate />} />
                <Route
                  path="conditionnement"
                  element={<ConditionnementPopup />}
                />
                <Route path="emplacement" element={<EmplacementPopup />} />
              </Route>

              {/* attendu routes */}
              <Route
                path="gestion-de-stock/attendu"
                element={<PermissionRoute hasPermission={canAccessAttendu} />}
              >
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <AttenduComponent />
                    </ProtectedRoute>
                  }
                />
                <Route path="chrono/sscc/:sscc" element={<SsccHistory />} />
                <Route path="attendu-create" element={<AttenduCreate />} />
                <Route
                  path="attendu-on-mission/:id"
                  element={<AttenduOnMission />}
                />
                <Route path="attendu-edit/:id" element={<AttenduEdit />} />
                <Route
                  path="reference/only-view/:id"
                  element={<RefEdit mode={MODE.VIEW} />}
                />
                <Route
                  path="fournisseur/only-view/:id"
                  element={<FournisseurView />}
                />
                <Route path="chrono/:id" element={<AttenduHistory />} />
              </Route>

              {/* commande routes */}
              <Route
                path="gestion-de-stock/commande"
                element={<PermissionRoute hasPermission={canAccessCommande} />}
              >
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <CommandeComponent />
                    </ProtectedRoute>
                  }
                />
                <Route path="chrono/sscc/:sscc" element={<SsccHistory />} />
                <Route path="commande-create" element={<CommandeCreate />} />
                <Route path="commande-edit/:id" element={<CommandeEdit />} />
                <Route
                  path="commande-preparation/:id"
                  element={
                    <CommandeMisEnPreparation
                      commandeType={CommandeType.Mis_en_preparation}
                    />
                  }
                />
                <Route
                  path="commande-envoye-en-mission/:id"
                  element={
                    <CommandeMisEnPreparation
                      commandeType={CommandeType.Envoye_en_mission}
                    />
                  }
                />
                <Route
                  path="commande-envoyer-en-chargement/:id"
                  element={
                    <CommandeMisEnPreparation
                      commandeType={CommandeType.Envoyer_en_chargement}
                    />
                  }
                />
                <Route
                  path="destinataire/only-view/:id"
                  element={<DestinataireView />}
                />
                <Route
                  path="transporteur/only-view/:id"
                  element={<TransporteurView />}
                />
                <Route path="chrono/:id" element={<CommandeHistory />} />
              </Route>

              {/* mission routes */}
              <Route
                path="gestion-de-stock/mission"
                element={<PermissionRoute hasPermission={canAccessMission} />}
              >
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <MissionComponent />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="mission-detail"
                  element={
                    <MissionDetailComponent screenType={ScreenType.popup} />
                  }
                />
              </Route>

              {/* history routes */}
              <Route
                path="gestion-de-stock/history"
                element={<PermissionRoute hasPermission={canAccessHistory} />}
              >
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <HistoryGeneral />
                    </ProtectedRoute>
                  }
                />
              </Route>

              {/* configurations routes */}
              <Route
                path="/configurations/reference"
                element={
                  <ProtectedRoute>
                    <ReferenceComponent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/configurations/reference/:id"
                element={
                  <ProtectedRoute>
                    <RefEdit mode={MODE.EDIT} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/configurations/reference/ref-create"
                element={
                  <ProtectedRoute>
                    <RefEdit mode={MODE.CREATE} />
                  </ProtectedRoute>
                }
              />

              {/* societe route */}
              <Route
                path="/configurations/societe"
                element={
                  <ProtectedRoute>
                    <SocieteComponent />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/configurations/societe/societe-create"
                element={
                  <ProtectedRoute>
                    <Societe mode={MODE.CREATE} />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path="/configurations/societe/societe-edit"
                element={
                  <ProtectedRoute>
                    <Societe mode={MODE.EDIT} />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path="/configurations/client"
                element={
                  <ProtectedRoute>
                    <ClientScreen />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/configurations/client/client-create"
                element={
                  <ProtectedRoute>
                    <ClientCreate />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path="/configurations/utilisateur"
                element={
                  <ProtectedRoute>
                    <UtilisateurScreen />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/configurations/utilisateur/utilisateur-create"
                element={
                  <ProtectedRoute>
                    <UtilisateurCreate />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path="/configurations/client/:id"
                element={
                  <ProtectedRoute>
                    <ClientEdit />
                  </ProtectedRoute>
                }
              ></Route>

              <Route
                path="/test/file/123"
                element={
                  <ProtectedRoute>
                    <TestPdf />
                  </ProtectedRoute>
                }
              ></Route>
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </AuthGuard>
    </div>
  )
}

export default App
