import {
  AllEntrepotToUpdateSociete,
  EntrepotInterface,
  Societe,
  SocieteArrayInterface,
  SocieteCreateResponseInterface,
  SocieteGetByIdResponseInterface,
} from '../models'
import baseAxios from './clientAPI'

const societeApi = {
  getAllEntrepot(payload: {
    limit: Number
    offset: Number
  }): Promise<EntrepotInterface> {
    const url = `/warehouse/warehouse-company?limit=${payload?.limit}&offset=${payload?.offset}`
    return baseAxios.get(url)
  },

  getAllSociete(payload: {
    limit: Number
    offset: Number
  }): Promise<SocieteArrayInterface> {
    const url = `/warehouse/company?limit=${payload.limit}&offset=${payload.offset}&order=DESC`
    return baseAxios.get(url)
  },

  createSociete(payload: Societe): Promise<SocieteCreateResponseInterface> {
    const url = '/warehouse/company'
    return baseAxios.post(url, payload)
  },

  getSocieteById(payload: {
    id: string
  }): Promise<SocieteGetByIdResponseInterface> {
    const url = `warehouse/company/id/${payload.id}`
    return baseAxios.get(url)
  },

  getAllEntrepotToUpdateSociete(payload: {
    id: string
  }): Promise<AllEntrepotToUpdateSociete> {
    const url = `warehouse/company/warehouse/${payload.id}`
    return baseAxios.get(url)
  },

  deleteSocieteById(payload: { id: string }): Promise<any> {
    const url = `warehouse/company/${payload.id}`
    return baseAxios.delete(url)
  },

  deleteMultipleSociete(payload: { ids: string[] }): Promise<any> {
    const url = `warehouse/company/many`
    return baseAxios.delete(url, { data: { ids: payload.ids } })
  },

  editSociete(payload: Societe): Promise<SocieteGetByIdResponseInterface> {
    const url = 'warehouse/company/update'
    return baseAxios.post(url, payload)
  },
}

export default societeApi
