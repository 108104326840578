import { useEffect, useState } from 'react'
import { ModalName } from '../features/stock/modal/customModalSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'

export const useChangeMenuColor = (modalName: ModalName) => {
  const [color, setColor] = useState<boolean>(false)
  const { name } = useSelector((state: RootState) => state.modal)

  useEffect(() => {
    setTimeout(() => {
      if (name === modalName) {
        setColor(true)
      } else setColor(false)
    }, 800)
  })

  return color
}
