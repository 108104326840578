import { StatusAttendu } from './../../../enum/StatusAttendu'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AttCommon } from '../../../models'

export enum ModalName {
  STOCK = 'stock',
  ATTENDU = 'attendu',
  COMMANDE = 'commande',
  REFERENCE = 'reference',
  TRANSPORTEUR = 'transporteur',
  DESTINATAIRE = 'destinataire',
  FOURNISSEUR = 'fournisseur',
  HISTORY = 'history',
  MISSION = 'mission',
  OTHER = 'other',
}

export enum ModalType {
  CREATION = 'creation',
  DISPLAY = 'display',
  EDIT = 'edit',
}

export interface ModalState {
  isOpen: boolean
  name: ModalName
  type: ModalType
  dataAttendu: AttCommon
  attenduStatus: StatusAttendu
}

const initialState: ModalState = {
  isOpen: false,
  name: ModalName.ATTENDU,
  type: ModalType.CREATION,
  dataAttendu: {},
  attenduStatus: StatusAttendu.CREATED,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    changeModalName: (state, action: PayloadAction<ModalName>) => {
      state.name = action.payload
    },
    changeModalType: (state, action: PayloadAction<ModalType>) => {
      state.type = action.payload
    },
    updateDataAttendu: (state, action: PayloadAction<AttCommon>) => {
      state.dataAttendu = action.payload
    },
    updateAttenduStatus: (state, action: PayloadAction<StatusAttendu>) => {
      state.attenduStatus = action.payload
    },
  },
})

export const {
  openModal,
  changeModalName,
  changeModalType,
  updateDataAttendu,
  updateAttenduStatus,
} = modalSlice.actions
export default modalSlice.reducer
