import { all } from 'redux-saga/effects'
import attenduSaga from '../redux/sagas/attenduSaga'
import clientSaga from '../redux/sagas/clientSaga'
import commandeSaga from '../redux/sagas/commandeSaga'
import destinataireSaga from '../redux/sagas/destinataireSaga'
import fournisseurSaga from '../redux/sagas/fournisseurSaga'
import referenceSaga from '../redux/sagas/referenceSaga'
import societeSaga from '../redux/sagas/societeSaga'
import transporteurSaga from '../redux/sagas/transporteurSaga'
import utilisateurSaga from '../redux/sagas/utilisateurSaga'
import accountSaga from '../redux/sagas/accountSaga'
import stockSaga from '../redux/sagas/stockSaga'
import missionSaga from '../redux/sagas/missionSaga'

export default function* rootSaga() {
  yield all([
    attenduSaga(),
    fournisseurSaga(),
    transporteurSaga(),
    destinataireSaga(),
    commandeSaga(),
    referenceSaga(),
    clientSaga(),
    utilisateurSaga(),
    societeSaga(),
    accountSaga(),
    stockSaga(),
    missionSaga(),
  ])
}
