import {
  CommandeEnvoyerMissionNewPayload,
  ReplenishRequestType,
  TotalAccessoriesApi,
} from './../models'
import Define from '../constants/define'
import { DocumentStatus } from '../enum/DocumentStatus'
import {
  Commande,
  CommandeArrayInterface,
  CommandeEnvoyerMissionRequest,
  CommandeInterface,
  CommandeRequest,
  CommandeResponse,
  CommandeUpdateResponse,
  FileResponseCmdSingleInterface,
  ReferenceInCommande,
  ExportCommandeType,
  ExportResponseInterface,
} from '../models'
import baseAxios from './clientAPI'
import { getUrlFilterParams } from '../utils'

export type CommandeCreatePayload = {
  body: Commande
  realTimeData: string
}

const commandeApi = {
  createCommande({
    body,
    realTimeData,
  }: CommandeCreatePayload): Promise<CommandeInterface> {
    const uri = '/warehouse/commande'
    return baseAxios.post(uri, { entry: body, realtime_object: realTimeData })
  },

  getAllCommande(payload: {}): Promise<CommandeArrayInterface> {
    const url = '/warehouse/commande/all'
    const { condition, pageSize, pageIndex } = getUrlFilterParams()
    const params = {
      limit: pageSize,
      offset: (pageIndex - 1) * pageSize || 0,
      user_id: localStorage.getItem(Define.USER_ID),
      role: localStorage.getItem(Define.ROLE),
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      ...condition,
    }
    return baseAxios.get(url, { params })
  },

  getTotalCommande(payload: {}): Promise<TotalAccessoriesApi> {
    const url = '/warehouse/accessories/get-total'
    const params = {
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      type: 'commande',
    }
    return baseAxios.get(url, { params })
  },
  getReferenceInStockBySscc(
    sscc: string,
    reference_id?: string
  ): Promise<ReferenceInCommande> {
    const url = '/warehouse/sscc/find-in-stock'
    const data = {
      sscc,
      reference_id,
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
    }
    return baseAxios.get(url, { params: data })
  },

  getCommandeById(payload: { id: string }): Promise<CommandeInterface> {
    const url = `/warehouse/commande/${payload.id}`
    return baseAxios.get(url)
  },

  deleteCommandeById(payload: {
    id: string
    updated_at: number
  }): Promise<any> {
    const url = `warehouse/commande/?updated_at=${payload.updated_at}&id=${payload.id}`
    return baseAxios.delete(url)
  },

  updateCommande(body: CommandeRequest): Promise<CommandeUpdateResponse> {
    const url = 'warehouse/commande'
    return baseAxios.patch(url, body)
  },

  validCommande(body: CommandeRequest): Promise<CommandeInterface> {
    const url = 'warehouse/commande/valid'
    return baseAxios.patch(url, body)
  },

  envoyerEnMission(
    body: CommandeEnvoyerMissionRequest
  ): Promise<CommandeInterface> {
    const url = 'warehouse/mission'
    return baseAxios.post(url, body)
  },

  envoyerEnMissionNew(
    body: CommandeEnvoyerMissionNewPayload
  ): Promise<CommandeInterface> {
    const url = 'warehouse/commande/send-on-mission'
    return baseAxios.post(url, { entry: body })
  },

  autoUploadFile(payload: {
    file: any
    description?: string
    commande_id: string
    created_at?: number
    statut?: DocumentStatus
  }): Promise<FileResponseCmdSingleInterface> {
    const url = `/files/commande/auto-upload-file`
    return baseAxios.post(url, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  annulerPreparation(body: CommandeResponse): Promise<CommandeInterface> {
    const url = 'warehouse/commande/cancel'
    return baseAxios.put(url, body)
  },

  cancelCommande(body: {
    entry: CommandeResponse
  }): Promise<CommandeInterface> {
    const url = 'warehouse/commande/cancel-commande'
    return baseAxios.post(url, body)
  },

  activeCommande(payload: {
    commande_id: string
    status: boolean
  }): Promise<any> {
    const url = `warehouse/commande/active-lot`
    return baseAxios.patch(url, payload)
  },

  checkLastest(payload: { id: string; updated_at: number }): Promise<any> {
    const url = `warehouse/commande/is-lastest`
    return baseAxios.post(url, payload)
  },

  searchStock(body: CommandeRequest): Promise<CommandeInterface> {
    const uri = '/warehouse/commande/search-stock'
    return baseAxios.post(uri, body)
  },

  replenishMission(body: ReplenishRequestType): Promise<{
    data?: {
      err_detail?: Array<{
        err_status: string
        sscc: string
      }>
    }
  }> {
    const uri = '/warehouse/mission/reappro'
    return baseAxios.post(uri, body)
  },

  resendLoadingMission(missionId: string) {
    const userId = localStorage.getItem(Define.USER_ID) || ''

    const params = new URLSearchParams({
      id: missionId,
      status: '60-7',
      type: '2',
      user_id: userId,
    })

    const url = `/warehouse/mission/update-status?${params.toString()}`
    return baseAxios.get(url)
  },

  exportAllCommande(
    data: ExportCommandeType
  ): Promise<ExportResponseInterface> {
    const url = '/warehouse/commande/export'
    return baseAxios.post(url, data)
  },
}

export default commandeApi
