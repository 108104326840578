import React, { useState, useEffect, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const handleError = (error: Error, errorInfo: React.ErrorInfo) => {
      console.error('Uncaught error:', error, errorInfo)
      setHasError(true)
    }

    const errorListener = (event: ErrorEvent) => {
      handleError(event.error, { componentStack: '' })
    }

    window.addEventListener('error', errorListener)

    return () => {
      window.removeEventListener('error', errorListener)
    }
  }, [])

  if (hasError) {
    return <h1>Something went wrong.</h1>
  }

  return <>{children}</>
}

export default ErrorBoundary
